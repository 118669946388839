<template>
  <div class="view view--login">
    <div class="view-body">
      <Section>
        <Container>
          <div class="login-block">
            <img class="login-logo" alt="Le logo de OSS" src="../../assets/logo-oss-full.svg" />

            <h1>Connexion</h1>

            <div class="form form--login">
              <form @submit.prevent="onSubmit()">
                <Input id="identifiant" label="Identifiant" placeholder="Ex : johndoe1234" required />

                <Input id="motdepasse" label="Mot de passe" type="password" required />

                <Btn btnType="submit" text="Se connecter" color="primary" block icon="chevron-big-right" icon-after />
              </form>
            </div>
            <p>
              <router-link :to="{ name: 'passwordForgottenStep1' }">Mot de passe oublié&nbsp;?</router-link>
            </p>
            <p>
              <router-link :to="{ name: 'usernameForgotten' }">Identifiant oublié&nbsp;?</router-link>
            </p>
            <div v-if="currentVersion" class="version-number"><em>v{{ currentVersion }}</em></div>
          </div>
        </Container>
      </Section>
    </div>
  </div>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'LogInView',

  components: {
    Input,
    Btn,
    Section,
    Container,
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      identifiant: this.yup.string().required(),
      motdepasse: this.yup.string().required(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleLogin(values)
    })

    return {
      user: {
        identifiant: '',
        motdepasse: '',
      },
      onSubmit,
      errors,
      isSubmitting,
      currentVersion: null,
    }
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.user.loggedIn
    },
  },
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$route.query.logout) {
        vm.handleLogout()
      }
    })
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: 'home' })
    }
    this.currentVersion = process.env.VERSION
  },
  methods: {
    handleLogin(values) {
      this.emitter.emit('open-loader')

      this.$store.dispatch('auth/login', values).then(
        () => {
          this.$store.dispatch('app/setReferentials').then(() => {
            console.log('app/setReferentials ok')
            this.$store.dispatch('app/setData').then(() => {
              console.log('app/setData ok')
              this.emitter.emit('alert', {
                type: 'success',
                content: 'Vous avez été connecté avec succès.',
              })
              this.emitter.emit('close-loader')
              this.$router.push({ name: 'home' })
            })
          })
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data.error,
          })
          this.formService.setFieldError('identifiant', ' ')
          this.formService.setFieldError('motdepasse', ' ')
          this.emitter.emit('close-loader')
        },
      )
    },
    handleLogout() {
      this.emitter.emit('open-loader')
      this.$store.dispatch('auth/logout').then(
        () => {
          this.emitter.emit('close-loader')
          window.location.href = '/connexion'
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
    },
  },
}
</script>
